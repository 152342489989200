import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["time"];
	static values = {
		dateStyle: { type: String, default: undefined },
		timeStyle: { type: String, default: "short" },
	};

	connect() {
		const formatter = new Intl.DateTimeFormat(undefined, {
			dateStyle: this.dateStyleValue,
			timeStyle: this.timeStyleValue,
		});
		const timeValue = new Date(this.timeTarget.dateTime);
		const formatted = formatter.format(timeValue);
		this.timeTarget.textContent = formatted;
		this.timeTarget.title = timeValue;
	}
}
